<script setup>
import { computed } from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'button',
    },
    is: {
        default: 'button',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    color: {
        type: String,
        default: 'default',
    },
});

const colors = {
    default: 'bg-gray-600 text-white disabled:bg-gray-100 disabled:text-gray-600',
    success:
        'bg-emerald-600 text-white disabled:opacity-30 disabled:pointer-events-none border-emerald-600 supports-hover:hover:border-emerald-700 supports-hover:hover:bg-emerald-700',
    danger: 'bg-rose-600 text-white disabled:opacity-30 disabled:pointer-events-none border-rose-600 supports-hover:hover:border-rose-700 supports-hover:hover:bg-rose-700',
};

const colorClasses = computed(() => colors[props.color] ?? colors.default);
</script>

<template>
    <template v-if="props.is === 'button'">
        <button
            :type="type"
            class="flex h-10 items-center rounded-lg border p-3 text-sm leading-tight disabled:cursor-not-allowed"
            :class="colorClasses"
            :disabled="disabled"
        >
            <slot />
        </button>
    </template>
    <template v-else>
        <component
            :is="props.is"
            class="flex h-10 items-center rounded-lg border p-3 text-sm leading-tight disabled:cursor-not-allowed"
            :class="colorClasses"
            :disabled="disabled"
        >
            <slot />
        </component>
    </template>
</template>
